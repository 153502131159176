import { ProjectData } from '../data/project-data';
import SiteButton from 'src/components/site-button';
import { onImageLoadUtility } from 'src/utility/functions';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './project.scss';

interface ProjectProps {
  projectData: ProjectData;
  latestRelease?: string;
  downloads?: Array<{
    name: string;
    url: string;
    osMatch?: boolean;
    cpuMatch?: boolean;
  }>;
  isOpenSource?: boolean;
}

function Project({
  projectData,
  latestRelease,
  downloads,
  isOpenSource = false,
}: ProjectProps) {
  return (
    <section className="project">
      <div className="project-image">
        <img
          src={projectData.logos.logo ?? undefined}
          alt="Project"
          className="fade-in"
          onLoad={onImageLoadUtility}
        />
      </div>
      <div className="project-content">
        <h3>{projectData.name}</h3>
        <p className="text-white">{projectData.descs.descShort}</p>
        <div className="project-links">
          <div>
            {isOpenSource && !projectData.isDocker && latestRelease && (
              <div className="project-release-info flex flex-col md:flex-row md:items-center gap-4 mb-4">
                <a
                  href={`https://github.com/blinklabs-io/${projectData.repo}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="github-link"
                >
                  <FontAwesomeIcon icon={faGithub} size="2xl" />
                </a>
                <p className="font-bold whitespace-nowrap">
                  Latest Release: {latestRelease}
                </p>
                {downloads && downloads.length > 0 && (
                  <div className="flex flex-col w-full sm:flex-row gap-2 items-center">
                    <select
                      className="w-full sm:w-auto px-5 py-3 bg-gray-700 rounded-lg bg-opacity-70 appearance-none font-bold"
                      defaultValue={
                        downloads.find((d) => d.osMatch && d.cpuMatch)?.url
                      }
                      id={`download-select-${projectData.name}`}
                    >
                      {downloads
                        .filter((d) => d.osMatch && d.cpuMatch)
                        .map((download) => (
                          <option key={download?.name} value={download?.url}>
                            {download?.name} ✓
                          </option>
                        ))}
                      {downloads
                        .filter((d) => !(d.osMatch && d.cpuMatch))
                        .map((download) => (
                          <option key={download.name} value={download.url}>
                            {download.name}
                          </option>
                        ))}
                    </select>
                    <button
                      onClick={() => {
                        const select = document.querySelector(
                          `#download-select-${projectData.name}`
                        ) as HTMLSelectElement;
                        const url = select.value;
                        if (url) window.open(url, '_blank');
                      }}
                      className="w-full sm:w-auto px-2 py-3 bg-[#D97706] rounded-lg hover:bg-amber-700 font-bold"
                    >
                      Download
                    </button>
                  </div>
                )}
              </div>
            )}

            {projectData.urls.map((url) => (
              <SiteButton
                label={url.name}
                url={url.link ? url.link : ''}
                key={url.name}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Project;
