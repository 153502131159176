import { ContactData } from "../data/project-data";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import logo from "src/assets/blink-logo.png";
import {
  faGithub,
  faLinkedin,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./contact.scss";

const Contact = ({ contactData }: { contactData: ContactData }) => {
  const getLink = (
    name: string,
    icon: IconProp,
    style: string,
    url?: string,
  ) => {
    return url ? (
      <a className="link" href={url}>
        <FontAwesomeIcon icon={icon} />
        {name}
      </a>
    ) : null;
  };

  const githubLink = getLink(
    "GitHub",
    faGithub,
    "text-github",
    contactData.links.github,
  );
  const linkedinLink = getLink(
    "LinkedIn",
    faLinkedin,
    "text-linkedin",
    contactData.links.linkedin,
  );
  const twitterLink = getLink(
    "Twitter",
    faTwitter,
    "text-twitter",
    contactData.links.twitter,
  );

  return (
    <div className="contact-card">
      <div className="card-content">
        <div className="image">
          {contactData.image ? (
            <img src={contactData.image} alt="contact"></img>
          ) : (
            <img className="none" src={logo} alt="contact"></img>
          )}
        </div>
        <div className="info">
          <h3 className="name">{contactData.name}</h3>
          {contactData.title && <i className="title">{contactData.title}</i>}
          {contactData.desc && (
            <p className="description">{contactData.desc}</p>
          )}
        </div>
      </div>
      <div className="links">
        {githubLink}
        {linkedinLink}
        {twitterLink}
      </div>
    </div>
  );
};

export default Contact;
