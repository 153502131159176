import fulllogo_nobuffer_jpg from '../assets/fulllogo_nobuffer.jpg';
import fulllogo_nobuffer_png from '../assets/fulllogo_nobuffer.png';
import fulllogo_transparent_nobuffer_png from '../assets/fulllogo_transparent_nobuffer.png';
import fulllogo_transparent_png from '../assets/fulllogo_transparent.png';
import fulllogo_jpg from '../assets/fulllogo.jpg';
import fulllogo_png from '../assets/fulllogo.png';
import grayscale_nobuffer_png from '../assets/grayscale_nobuffer.png';
import grayscale_transparent_nobuffer_png from '../assets/grayscale_transparent_nobuffer.png';
import grayscale_transparent_png from '../assets/grayscale_transparent.png';
import grayscale_png from '../assets/grayscale.png';
import icononly_nobuffer_png from '../assets/icononly_nobuffer.png';
import icononly_transparent_nobuffer_png from '../assets/icononly_transparent_nobuffer.png';
import icononly_transparent_png from '../assets/icononly_transparent.png';
import icononly_png from '../assets/icononly.png';
import print_transparent_eps from '../assets/print_transparent.eps';
import print_transparent_pdf from '../assets/print_transparent.pdf';
import print_transparent_svg from '../assets/print_transparent.svg';
import print_eps from '../assets/print.eps';
import print_pdf from '../assets/print.pdf';
import print_svg from '../assets/print.svg';
import textonly_nobuffer_png from '../assets/textonly_nobuffer.png';
import textonly_png from '../assets/textonly.png';

const assets = [
  {
    category: 'Full Logo',
    variants: [
      {
        name: 'No Buffer',
        img: fulllogo_nobuffer_png,
        file: fulllogo_nobuffer_png,
        type: 'PNG',
        backgroundColor: 'black',
      },
      {
        name: ' No Buffer',
        img: fulllogo_nobuffer_jpg,
        file: fulllogo_nobuffer_jpg,
        type: 'JPG',
        backgroundColor: 'black',
      },
      {
        name: 'Transparent No Buffer',
        img: fulllogo_transparent_nobuffer_png,
        file: fulllogo_transparent_nobuffer_png,
        type: 'PNG',
        backgroundColor: 'white',
      },
      {
        name: 'Transparent',
        img: fulllogo_transparent_png,
        file: fulllogo_transparent_png,
        type: 'PNG',
        backgroundColor: 'white',
      },
      {
        name: 'Full Logo PNG',
        img: fulllogo_png,
        file: fulllogo_png,
        type: 'PNG',
        backgroundColor: 'black',
      },
      {
        name: 'Full Logo Logo JPG',
        img: fulllogo_jpg,
        file: fulllogo_jpg,
        type: 'JPG',
        backgroundColor: 'black',
      },
    ],
  },
  {
    category: 'Grayscale',
    variants: [
      {
        name: ' No Buffer',
        img: grayscale_nobuffer_png,
        file: grayscale_nobuffer_png,
        type: 'PNG',
        backgroundColor: 'black',
      },
      {
        name: 'Grayscale',
        img: grayscale_png,
        file: grayscale_nobuffer_png,
        type: 'PNG',
        backgroundColor: 'black',
      },
      {
        name: 'Transparent No Buffer',
        img: grayscale_transparent_nobuffer_png,
        file: grayscale_transparent_nobuffer_png,
        type: 'PNG',
        backgroundColor: 'white',
      },
      {
        name: 'Transparent',
        img: grayscale_transparent_png,
        file: grayscale_nobuffer_png,
        type: 'PNG',
        backgroundColor: 'white',
      },
    ],
  },
  {
    category: 'Icon',
    variants: [
      {
        name: 'No Buffer',
        img: icononly_nobuffer_png,
        file: icononly_nobuffer_png,
        type: 'PNG',
        backgroundColor: 'black',
      },
      {
        name: 'Transparent No Buffer',
        img: icononly_transparent_nobuffer_png,
        file: icononly_transparent_nobuffer_png,
        type: 'PNG',
        backgroundColor: 'white',
      },
      {
        name: 'Transparent',
        img: icononly_transparent_png,
        file: icononly_transparent_png,
        type: 'PNG',
        backgroundColor: 'white',
      },
      {
        name: 'Icon',
        img: icononly_png,
        file: icononly_png,
        type: 'PNG',
        backgroundColor: 'black',
      },
    ],
  },
  {
    category: 'Print',
    variants: [
      {
        name: 'Transparent',
        img: print_transparent_svg,
        file: print_transparent_svg,
        type: 'SVG',
        backgroundColor: 'white',
      },
      {
        name: 'Print',
        img: print_svg,
        file: print_svg,
        type: 'SVG',
        backgroundColor: 'black',
      },
      {
        name: 'Transparent EPS',
        img: print_transparent_svg,
        file: print_transparent_eps,
        type: 'EPS',
        backgroundColor: 'white',
      },
      {
        name: 'Transparent PDF',
        img: print_transparent_svg,
        file: print_transparent_pdf,
        type: 'PDF',
        backgroundColor: 'white',
      },
      {
        name: 'EPS',
        img: print_svg,
        file: print_eps,
        type: 'EPS',
        backgroundColor: 'black',
      },
      {
        name: 'PDF',
        img: print_svg,
        file: print_pdf,
        type: 'PDF',
        backgroundColor: 'black',
      },
    ],
  },
  {
    category: 'Text Only',
    variants: [
      {
        name: 'Text Only No Buffer',
        img: textonly_nobuffer_png,
        file: textonly_nobuffer_png,
        type: 'PNG',
        backgroundColor: 'black',
      },
      {
        name: 'Text Only',
        img: textonly_png,
        file: textonly_png,
        type: 'PNG',
        backgroundColor: 'black',
      },
    ],
  },
];
const brandColors = [
  { name: 'White', color: '#FFFFFF' },
  { name: 'Blink Labs Blue', color: '#4285F4' },
  { name: 'Primary Orange', color: '#D97706' },
  { name: 'Gradient', color: '#0F3F8A, #000A1B', isGradient: true },
];

interface Colors {
  color: string | 'Linear Gradient';
  name: string;
  isGradient?: boolean;
}

const BrandColors = ({ color, name, isGradient = false }: Colors) => (
  <div className="aspect-square w-full h-80">
    <div
      className="w-full h-full rounded-2xl shadow-md flex flex-col justify-end overflow-hidden"
      style={
        isGradient
          ? {
              backgroundImage:
                'linear-gradient(to bottom, #0f3f8a, #000a1b 70%)',
            }
          : { backgroundColor: color }
      }
    >
      <div className="p-6 text-center bg-black  bg-opacity-50">
        <h3 className="text-xl font-semibold text-white">{name}</h3>{' '}
        <p className="text-md text-gray-300 mt-2">{color}</p>
      </div>
    </div>
  </div>
);

const MediaKit = () => {
  const handleDownload = (file: string, fileName: string) => {
    const link = document.createElement('a');
    link.href = file;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <>
      <section className="outer-flex about section-content">
        <div className="inner-flex inner">
          <div className="content">
            <h1>Media Kit</h1>
            <div className="intro">
              <div className="content">
                <p>
                  The Blink Labs Brand Assets is a collection of our usable
                  media resources that are available for download.
                </p>
              </div>
            </div>
            <div className="">
              {assets.map((category, index) => (
                <div key={index} className="category-section mb-8">
                  <h2 className=" font-semibold mb-4 text-white">
                    {category.category}
                  </h2>
                  <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 ">
                    {category.variants.map((variant, variantIndex) => (
                      <div
                        key={variantIndex}
                        className="aspect-w-1 aspect-h-1 w-full min-h-80"
                      >
                        <div className="w-full min-h-full rounded-2xl flex flex-col  overflow-hidden">
                          <div
                            className={`flex-grow flex items-center justify-center p-4 ${
                              variant.backgroundColor === 'black'
                                ? 'bg-black'
                                : 'bg-white'
                            }`}
                          >
                            {' '}
                            <img
                              src={variant.img}
                              alt={variant.name}
                              className="max-w-2/3 max-h-2/3 object-contain"
                            />
                          </div>
                          <div className="download-button w-full  bg-[#4285F4] text-white font-bold py-4 px-4 text-md">
                            <div className="truncate">{variant.name}</div>
                            <div>
                              <button
                                className="underline mt-1 hover:text-[#ffaa4e]"
                                onClick={() =>
                                  handleDownload(
                                    variant.file,
                                    `${variant.name.toLowerCase()}.${variant.type.toLowerCase()}`
                                  )
                                }
                              >
                                Download {variant.type}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <section className="outer-flex about section-content border mb-20 mt-20">
        <div className="inner-flex inner">
          <div className="content mt-20">
            <h2>Blink Lab Colors</h2>
            <p>
              The Blink Labs Colors are a collection of our Brand Colors that is
              used across our site
            </p>
            <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 mb-8">
              {brandColors.map((colorInfo, index) => (
                <BrandColors key={index} {...colorInfo} />
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MediaKit;
