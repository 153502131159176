import { JobData } from "../data/project-data";

import "./job.scss";
import SiteButton from "./site-button";

function Job({ jobData }: { jobData: JobData }) {
  return (
    <section className="job">
      <h3>{jobData.title}</h3>
      <p>{jobData.desc}</p>
      <SiteButton url={jobData.url} label={jobData.linkTitle || "Apply"} />
    </section>
  );
}

export default Job;
