import { NavLink } from "src/data/project-data";
import { ChangeEvent, useEffect, useState } from "react";
import { getLink, scrollTopFunction } from "src/utility/functions";

import "./mobile-nav.scss";

function MobileNav({ navLinks }: { navLinks: NavLink[] }) {
  const [menuOpen, setMenuOpen] = useState(false);
  const [mobileNav, setMobileNav] = useState<Element | null>(null);

  // helper function to run preventDefault
  function preventDefault(e: { preventDefault: () => void }) {
    e.preventDefault();
  }

  // used to close the nav when sent from the base header component
  function onCloseNavEvent(event: Event) {
    setMenuOpen(false);
  }

  useEffect(() => {
    setMobileNav(document.querySelector(".mobile-nav"));

    // used to close the nav when sent from the base header component
    document.addEventListener("close-nav", onCloseNavEvent);

    return () => {
      document.removeEventListener("close-nav", onCloseNavEvent);
    };
  }, []);

  useEffect(() => {
    scrollTopFunction();
    document.body.classList.toggle("no-scroll", menuOpen);
  }, [menuOpen]);

  function onNavToggleClick(event: ChangeEvent) {
    const checkbox = event.target as HTMLInputElement;
    if (checkbox) {
      if (checkbox.checked) {
        // block pointer events
        mobileNav?.addEventListener("pointermove", preventDefault);
        mobileNav?.addEventListener("touchmove", preventDefault);
      } else {
        mobileNav?.removeEventListener("pointermove", preventDefault);
        mobileNav?.removeEventListener("touchmove", preventDefault);
      }

      setMenuOpen(checkbox.checked);
    }
  }

  function onLinkClick() {
    setMenuOpen(false);
  }

  return (
    <>
      <div className={"nav-toggle"}>
        <input
          id="hamburger-checkbox"
          type="checkbox"
          onChange={onNavToggleClick}
          checked={menuOpen}
        />
        <label className="hamburger" htmlFor="hamburger-checkbox">
          <div className="top"></div>
          <div className="meat"></div>
          <div className="bottom"></div>
        </label>
      </div>
      <div
        className={["nav-overlay", menuOpen ? "nav-open" : ""]
          .filter((e) => !!e)
          .join(" ")}
      >
        <ul className="mobile-nav">
          {navLinks.map((navLink, index) => (
            <li className="mobile-nav-section" data-index={index} key={index}>
              {navLink.subMenu && navLink.subMenu.length > 0 ? (
                <>
                  {navLinks.length > 1 && (
                    <label className="sub-menu-label">{navLink.name}</label>
                  )}
                  <ul>
                    {navLink.subMenu.map((subNavLink, subIndex) => (
                      <li key={subIndex}>
                        {getLink({
                          url: subNavLink.url,
                          icon: subNavLink.icon,
                          innerElement: subNavLink.name,
                          onClick: onLinkClick,
                        })}
                      </li>
                    ))}
                  </ul>
                </>
              ) : (
                getLink({
                  url: navLink.url,
                  icon: navLink.icon,
                  innerElement: navLink.name,
                  onClick: onLinkClick,
                })
              )}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}

export default MobileNav;
