import Job from "src/components/job";
import { JobData, JobsDataGroup } from "src/data/project-data";
import "./page.scss";
import "./jobs.scss";
import { useEffect } from "react";
import { onImageLoadUtility, scrollTopFunction } from "src/utility/functions";
import { useNavigate } from "react-router-dom";

interface IJobsProps {
  jobsDataGroup: JobsDataGroup;
}

function Jobs({ jobsDataGroup }: IJobsProps) {
  const navigate = useNavigate();
  useEffect(() => {
    scrollTopFunction();
  }, [navigate]);

  return (
    <>
      <section className="outer-flex jobs-intro">
        <div className="inner-flex inner">
          <div className="copy">
            <h2>{jobsDataGroup.heading}</h2>
            {jobsDataGroup.desc && <p>{jobsDataGroup.desc}</p>}
          </div>
          <div className="image">
            <img
              src={"/images/jobs/desk_guy.png"}
              className="jobs-image fade-in"
              alt="Jobs"
              onLoad={onImageLoadUtility}
            />
          </div>
        </div>
      </section>
      <section className="outer-flex jobs section-content">
        <div className="inner-flex inner">
          {jobsDataGroup.jobs.map((job: JobData, index) => {
            return <Job jobData={job} key={index} />;
          })}
        </div>
      </section>
    </>
  );
}

export default Jobs;
