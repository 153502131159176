import { MouseEvent, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "src/data/project-data";
import { getLink } from "src/utility/functions";

import "./desktop-nav.scss";

function DesktopNav({ navLinks }: { navLinks: NavLink[] }) {
  let activeNavItem: HTMLElement | null = null;

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);
    return function cleanup() {
      document.removeEventListener("click", handleDocumentClick);
    };
  });

  function clearAllActive() {
    const activeNavItems = document.querySelectorAll(".drop-down-open");
    activeNavItems.forEach((activeElement) => {
      activeElement.classList.remove("drop-down-open");
    });
  }

  function handleDocumentClick() {
    clearAllActive();
    activeNavItem = null;
  }

  function handleClick(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();

    const navItemButton = event.target as HTMLAnchorElement;
    const navItem = navItemButton?.parentElement;
    const index = navItem?.getAttribute("data-index") || null;

    if (navItem && index != null) {
      const navLink = navLinks[parseInt(index)];

      clearAllActive();

      if (navLink.subMenu && navLink.subMenu.length > 0) {
        if (activeNavItem !== navItem) {
          activeNavItem = navItem;
          navItem.classList.toggle("drop-down-open");
        } else {
          activeNavItem = null;
        }
      }
    }
  }

  return (
    <ul className="desktop-nav">
      {navLinks.map((navLink, index) => (
        <li className="desktop-nav-item" data-index={index} key={index}>
          {navLink.subMenu && navLink.subMenu.length > 0 ? (
            // submenu link with dropdown
            <>
              <button
                className="white-hover-amber"
                key={index}
                onClick={handleClick}
              >
                {navLink.icon && <FontAwesomeIcon icon={navLink.icon} />}
                {navLink.name}
              </button>
              <ul className="drop-down">
                {navLink.subMenu.map((subNavLink, subIndex) => (
                  <li className="drop-down-item" key={subIndex}>
                    {getLink({
                      url: subNavLink.url,
                      icon: subNavLink.icon,
                      innerElement: subNavLink.name,
                    })}
                  </li>
                ))}
              </ul>
            </>
          ) : (
            // single link
            <>
              <div className="px-4 py-2 text-xl">
                {getLink({
                  url: navLink.url,
                  icon: navLink.icon,
                  innerElement: navLink.name,
                  key: index,
                })}
              </div>
            </>
          )}
        </li>
      ))}
    </ul>
  );
}

export default DesktopNav;
