import Project from 'src/components/project';
import { ProjectDataGroup, ProjectData } from 'src/data/project-data';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { scrollTopFunction } from 'src/utility/functions';

import './page.scss';
import './projects.scss';
import UAParser from 'ua-parser-js';
import {
  getSystemCpuType,
  getDownloadButtonOsName,
  getDownloadButtonCpuType,
  getDownloadButtonCpuTypeName,
  getDownloadButtonName,
  doesOsMatch,
  doesCpuTypeMatch,
} from './downloads';

interface IProjectsProps {
  projectDataGroup: ProjectDataGroup;
  latestRelease?: string;
}

// TODO: Add this to env, current rate limit is 60/hr without authentication
// const GITHUB_TOKEN = '';

function Projects({ projectDataGroup, latestRelease }: IProjectsProps) {
  const [latestReleases, setLatestReleases] = useState<{
    [key: string]: {
      version: string;
      assets: Array<{
        name: string;
        url: string;
        osMatch?: boolean;
        cpuMatch?: boolean;
      }>;
    };
  }>({});

  useEffect(() => {
    scrollTopFunction();

    const fetchLatestReleases = async () => {
      const releases: { [key: string]: any } = {};
      const parser = new UAParser();
      const systemOsName = parser.getOS().name || '';
      const systemCpuType = getSystemCpuType(parser);

      // const config = {
      //   headers: {
      //     Authorization: `token ${GITHUB_TOKEN}`,
      //     Accept: 'application/vnd.github.v3+json',
      //   },
      // };

      for (const project of projectDataGroup.projects) {
        try {
          if (project.isDocker) {
            continue;
          }
          // Check cache
          const cachedData = localStorage.getItem(`release-${project.repo}`);
          const cacheTimestamp = localStorage.getItem(
            `release-${project.repo}-timestamp`
          );
          const cacheAge = cacheTimestamp
            ? Date.now() - parseInt(cacheTimestamp)
            : Infinity;

          if (cachedData && cacheAge < 1800000) {
            console.log('Using cached data');
            releases[project.repo] = JSON.parse(cachedData);
          } else {
            console.log('Fetching latest release');
            const response = await axios.get(
              `https://api.github.com/repos/blinklabs-io/${project.repo}/releases/latest`
            );

            // const response = await axios.get(
            //   `https://api.github.com/repos/blinklabs-io/${project.repo}/releases/latest`,
            //   config
            // );

            const processedAssets = response.data.assets.map((asset: any) => {
              const fileNameParts = asset.name.split('-');

              console.log('Processing asset:', asset.name);
              console.log('File name parts:', fileNameParts);
              const osName = getDownloadButtonOsName(fileNameParts);
              const cpuType = getDownloadButtonCpuType(fileNameParts);
              const cpuTypeName = getDownloadButtonCpuTypeName(cpuType, osName);
              const name = getDownloadButtonName(osName, cpuTypeName);

              return {
                name: name,
                url: asset.browser_download_url,
                osMatch: doesOsMatch(osName, systemOsName),
                cpuMatch: doesCpuTypeMatch(cpuType, systemCpuType),
                osName: osName,
              };
            });

            releases[project.repo] = {
              version: response.data.tag_name,
              assets: processedAssets,
            };

            localStorage.setItem(
              `release-${project.repo}`,
              JSON.stringify(releases[project.repo])
            );
            localStorage.setItem(
              `release-${project.repo}-timestamp`,
              Date.now().toString()
            );
          }
        } catch (error) {
          console.error(
            `Error fetching latest release for ${project.repo}:`,
            error
          );
          const cachedData = localStorage.getItem(`release-${project.repo}`);
          if (cachedData) {
            releases[project.repo] = JSON.parse(cachedData);
          } else {
            releases[project.repo] = {
              version: 'Version Not Found',
              assets: [],
            };
          }
        }
      }
      setLatestReleases(releases);
    };

    fetchLatestReleases();
  }, [projectDataGroup.projects]);

  return (
    <>
      <section className="outer-flex projects-intro">
        <div className="inner-flex inner">
          <h2>{projectDataGroup.heading}</h2>
          {projectDataGroup.desc && <p>{projectDataGroup.desc}</p>}
        </div>
      </section>
      <section className="outer-flex projects-intro section-content">
        <div className="inner-flex inner gap-40">
          {projectDataGroup.projects.map((project: ProjectData) => {
            const releaseData = latestReleases[project.repo];
            const sortedAssets = [...(releaseData?.assets || [])].sort(
              (a, b) => {
                if (a.osMatch && !b.osMatch) return -1;
                if (!a.osMatch && b.osMatch) return 1;
                return 0;
              }
            );

            return (
              <Project
                projectData={project}
                key={project.name}
                latestRelease={releaseData?.version}
                downloads={sortedAssets}
                isOpenSource={project.isOpenSource}
              />
            );
          })}
        </div>
      </section>
    </>
  );
}

export default Projects;
