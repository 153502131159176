import logo from "src/assets/blink-labs-logo.svg";
import DesktopNav from "./desktop-nav";
import MobileNav from "./mobile-nav";
import { navLinks } from "src/data/project-data";
import { Link } from "react-router-dom";
import { onImageLoadUtility } from "src/utility/functions";

import "./header.scss";

function Header({ className = "" }) {
  function onLinkClick() {
    const event = new Event("close-nav");
    document.dispatchEvent(event);
  }

  return (
    <header className={`${className} outer-flex blink-header`}>
      <div className="inner-flex">
        <Link to="/" onClick={onLinkClick}>
          <img
            src={logo}
            className="logo"
            alt="logo"
            onLoad={onImageLoadUtility}
          />
        </Link>
        <DesktopNav navLinks={navLinks} />
        <MobileNav navLinks={navLinks} />
      </div>
    </header>
  );
}

export default Header;
