import mainImage from 'src/assets/main-header-image.svg';
import SiteButton from 'src/components/site-button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { projectLinks } from 'src/data/project-data';
import {
  getLink,
  onImageLoadUtility,
  scrollTopFunction,
} from 'src/utility/functions';
import { useEffect } from 'react';

import './page.scss';
import './main.scss';

function Main() {
  useEffect(() => {
    scrollTopFunction();
  }, []);

  return (
    <>
      <section className="outer-flex">
        <div className="inner-flex">
          <div className="home-intro">
            <div className="intro-content">
              <div className="inner">
                <h1>
                  Pioneering
                  <br />
                  Open Source Technology
                </h1>
                <p>for a Decentralized Tomorrow</p>
                <SiteButton label="Find Out More" url="/projects-open-source" />
              </div>
            </div>
            <img
              src={mainImage}
              className="intro-image fade-in"
              alt="Main"
              onLoad={onImageLoadUtility}
            />
          </div>
        </div>
      </section>
      <section className="outer-flex about-intro section-content border">
        <div className="inner-flex inner">
          <h2>Building Solutions and Partnerships</h2>
          <p>
            We don't just build software; we build connections and foster
            collaboration. As active community members, we're deeply involved in
            shaping the future of the blockchain through our technology, our
            alliances and our community contributions to Cardano, Project
            Catalyst, and PRAGMA. We are committed to our open source principles
            and our belief in the transformative power of blockchain technology.
          </p>
        </div>
      </section>
      <section className="outer-flex featured">
        <div className="inner-flex columns">
          {projectLinks.subMenu?.map((navLink, index) => (
            <div className="feature" key={index}>
              {getLink({
                url: navLink.url,
                innerElement: (
                  <>
                    {navLink.icon && (
                      <div className="icon">
                        <FontAwesomeIcon icon={navLink.icon} size="5x" />
                      </div>
                    )}
                    <h3>{navLink.name}</h3>
                  </>
                ),
                key: index,
              })}
              <p>{navLink.desc}</p>
            </div>
          ))}
        </div>
      </section>
    </>
  );
}

export default Main;
