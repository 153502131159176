import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import "src/index.scss";
import App from "src/App";

import reportWebVitals from "src/reportWebVitals";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

window.addEventListener("resize", () => {
  document.documentElement.style.setProperty(
    "--window-inner-height",
    `${window.innerHeight}px`,
  );
});

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
);

// reportWebVitals(console.log);
reportWebVitals();
