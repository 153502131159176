import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BaseSyntheticEvent, ReactNode } from "react";
import { NavLink } from "react-router-dom";
import UAParser from "ua-parser-js";

interface IGetLinkProps {
  url?: string;
  innerElement?: ReactNode;
  customClasses?: string;
  icon?: IconProp;
  iconSize?:
    | "xs"
    | "lg"
    | "sm"
    | "1x"
    | "2x"
    | "3x"
    | "4x"
    | "5x"
    | "6x"
    | "7x"
    | "8x"
    | "9x"
    | "10x";
  key?: number;
  title?: string;
  onClick?: () => void;
}

// returns an html anchor tag or react-router-dom link tag, depending on the url
export function getLink({
  url,
  innerElement,
  customClasses,
  icon,
  iconSize,
  key,
  title,
  onClick,
}: IGetLinkProps) {
  if (url && url.startsWith("http")) {
    return (
      <a
        className={customClasses ? customClasses : ""}
        key={key}
        href={url || "#"}
        target="_blank"
        rel="noreferrer"
        title={title}
      >
        {icon && (
          <span className="link-icon">
            <FontAwesomeIcon icon={icon} size={iconSize} />
          </span>
        )}
        {innerElement && (
          <span className="link-element" key={key}>
            {innerElement}
          </span>
        )}
      </a>
    );
  } else {
    return (
      <NavLink
        className={customClasses ? customClasses : ""}
        key={key}
        to={url || "#"}
        onClick={onClick}
        title={title}
      >
        {icon && (
          <span className="link-icon">
            <FontAwesomeIcon icon={icon} size={iconSize} />
          </span>
        )}
        {innerElement && (
          <span className="link-element" key={key}>
            {innerElement}
          </span>
        )}
      </NavLink>
    );
  }
}

export function onImageLoadUtility(event: BaseSyntheticEvent) {
  event.target.classList.add("loaded");
}

export function scrollTopFunction() {
  const parser = new UAParser();
  const isMobile = parser?.getDevice().type === "mobile";
  const yValue = isMobile ? 1 : 0;
  window.scrollTo(0, yValue);
}
