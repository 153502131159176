import SiteHeader from "src/components/header";
import SiteFooter from "./components/footer";
import Router from "src/layouts/router";

import "src/styles.scss";

export default function App() {
  return (
    <div className="wrapper">
      <SiteHeader />
      <Router />
      <SiteFooter />
    </div>
  );
}
