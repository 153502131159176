import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { DownloadRepoData, downloadsData } from 'src/data/project-data';
import { UAParser } from 'ua-parser-js';
import { getLink, scrollTopFunction } from 'src/utility/functions';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import SiteButton from 'src/components/site-button';

import './page.scss';
import './downloads.scss';

export const DownloadOsLabels = {
  MAC_OS: 'macOS',
  WINDOWS: 'Windows',
  LINUX: 'Linux',
  FREE_BSD: 'FreeBSD',
} as const;

export const DownloadOsTypes = [
  [DownloadOsLabels.MAC_OS, 'mac', 'mac os', 'darwin'],
  [DownloadOsLabels.WINDOWS, 'windows'],
  [
    DownloadOsLabels.LINUX,
    'linux',
    'chromium os',
    'ubuntu',
    'debian',
    'fedora',
    'redhat',
    'centos',
    'suse',
    'gentoo',
    'mandriva',
    'mageia',
    'mint',
    'arch',
    'slackware',
    'vectorlinux',
    'pup',
  ],
  [DownloadOsLabels.FREE_BSD, 'freebsd'],
] as [string, ...string[]][];

export const DownloadCpuLabels = {
  APPLE_SILICON: 'Apple Silicon',
  INTEL: 'Intel',
  X86_64: 'x64',
  ARM64: 'arm64',
} as const;

export const DownloadMacCpuTypes = [
  [DownloadCpuLabels.APPLE_SILICON, 'arm64'],
  [DownloadCpuLabels.INTEL, 'amd64'],
] as [string, ...string[]][];

export const DownloadCpuTypes = [
  [DownloadCpuLabels.ARM64, 'arm64'],
  [DownloadCpuLabels.X86_64, 'amd64'],
] as [string, ...string[]][];

export function getDownloadVersion(fileNameParts: Array<string>) {
  const version = fileNameParts[1];
  return version?.replace('v', '') || '';
}

export function getDownloadButtonOsName(fileNameParts: Array<string>) {
  const osName = fileNameParts[fileNameParts.length - 2];
  for (const osType of DownloadOsTypes) {
    for (const osTypePart of osType) {
      if (osTypePart === osName) {
        return osType[0];
      }
    }
  }
  return '';
}

export function getDownloadButtonCpuType(fileNameParts: Array<string>) {
  return fileNameParts[fileNameParts.length - 1].split('.')[0];
}

export function getDownloadButtonCpuTypeName(
  cpuType: string,
  returnOsName: string
) {
  if (returnOsName === DownloadOsLabels.MAC_OS) {
    for (const macCpuType of DownloadMacCpuTypes) {
      for (const macCpuTypePart of macCpuType) {
        if (macCpuTypePart === cpuType) {
          return macCpuType[0];
        }
      }
    }
  } else {
    for (const cpuTypePart of DownloadCpuTypes) {
      for (const cpuTypePartPart of cpuTypePart) {
        if (cpuTypePartPart === cpuType) {
          return cpuTypePart[0];
        }
      }
    }
  }
  return '';
}

export function getDownloadButtonName(osName: string, cpuType: string) {
  return osName + ' ' + cpuType;
}

export function doesOsMatch(fileOsName: string, systemOsName: string) {
  systemOsName = systemOsName.toLowerCase();
  for (const osType of DownloadOsTypes) {
    if (osType.includes(systemOsName)) {
      return osType.includes(fileOsName);
    }
  }
  return false;
}

export function doesCpuTypeMatch(
  fileCpuType: string,
  systemCpuType: string | null
) {
  if (systemCpuType) {
    systemCpuType = systemCpuType.toLowerCase();
    for (const cpuType of DownloadCpuTypes) {
      if (cpuType.includes(systemCpuType)) {
        return cpuType.includes(fileCpuType);
      }
    }
  }
  return false;
}

export function getSystemCpuType(parser: UAParser) {
  if (parser.getOS().name?.toLowerCase() === 'mac os') {
    let cpuType = null;
    // Safari cannot detect Apple Silicon yet. We'll leave cpu as null in this case.
    if (
      navigator.userAgent.indexOf('Safari') > -1 &&
      navigator.userAgent.indexOf('Chrome') <= -1
    ) {
      // do nothing
    } else {
      // try to detect Apple Silicon with glcontext trick
      cpuType = 'amd64';
      try {
        var glcontext = document.createElement('canvas').getContext('webgl');
        if (glcontext) {
          var debugrenderer = glcontext.getExtension(
            'WEBGL_debug_renderer_info'
          );
          var renderername =
            (debugrenderer &&
              glcontext.getParameter(debugrenderer.UNMASKED_RENDERER_WEBGL)) ||
            '';
          if (
            renderername.match(/Apple M/) ||
            renderername.match(/Apple GPU/)
          ) {
            cpuType = 'arm64';
          }
        }
      } catch (e) {}
    }
    return cpuType;
  }
  return parser.getCPU().architecture || null;
}

function Downloads() {
  const hasDataFetchedForThisPage = useRef(false);
  const [repoURLs, setRepoURLs] = useState<DownloadRepoData[]>([]);

  useEffect(() => {
    scrollTopFunction();

    // only fetch once in this useEffect
    if (!hasDataFetchedForThisPage.current) {
      const parser = new UAParser();
      const systemOsName = parser.getOS().name || '';
      const systemCpuType = getSystemCpuType(parser);

      // create promise array to fetch all releases
      let promiseArr = downloadsData.repos.map((repo) => {
        if (repo.downloadsCache) {
          // if downloads already fetched for this repo, pull from cache and resolve
          repo.downloads = JSON.parse(repo.downloadsCache);
          return Promise.resolve();
        }
        return axios
          .get(
            `https://api.github.com/repos/${repo.userName}/${repo.repo}/releases/latest`
          )
          .then((response) => {
            repo.downloads = [];
            response.data.assets.forEach(
              (asset: { name: string; browser_download_url: string }) => {
                // replace repo.repo string in name (to avoid complex names with dashes)
                asset.name = asset.name.replace(repo.repo, 'name');
                const fileNameParts = asset.name.split('-');
                const version = getDownloadVersion(fileNameParts);
                const osName = getDownloadButtonOsName(fileNameParts);
                const cpuType = getDownloadButtonCpuType(fileNameParts);
                const cpuTypeName = getDownloadButtonCpuTypeName(
                  cpuType,
                  osName
                );
                const name = getDownloadButtonName(osName, cpuTypeName);
                const osMatch = doesOsMatch(osName, systemOsName);
                const cpuMatch = doesCpuTypeMatch(cpuType, systemCpuType);
                repo.downloads?.push({
                  name: name,
                  url: asset.browser_download_url,
                  osMatch: osMatch,
                  cpuMatch: cpuMatch,
                  version: version,
                  osName: osName,
                });
                repo.version = version;
                repo.hasCpuMatch = cpuMatch ? cpuMatch : repo.hasCpuMatch;
                repo.hasOsMatch = osMatch ? osMatch : repo.hasOsMatch;
              }
            );
            // deep copy downloads to cache
            repo.downloadsCache = JSON.stringify(repo.downloads);
          })
          .catch((error) => {
            //console.error("Error fetching releases:", error);
          });
      });
      // wait for all releases to be fetched and then set state for UI to render
      Promise.all(promiseArr).then(() => {
        setRepoURLs(downloadsData.repos);
      });
      hasDataFetchedForThisPage.current = true;
    }
  }, []);

  return (
    <>
      <div className="w-full items-center">
        <section className="outer-flex downloads-intro">
          <div className="inner-flex inner">
            <h2>Downloads</h2>
            <h3>Blink Labs' suite of CLI tools and API services</h3>
            <p>
              This page attempts to detect which file type your system needs.
              Other choices for the latest version of each application are
              below. Older releases are available on each application's Releases
              page on GitHub.
            </p>
          </div>
        </section>
        <section className="outer-flex downloads">
          <div className="inner-flex inner">
            {repoURLs.map((repo) => (
              <div className="repo" key={repoURLs.indexOf(repo)}>
                <header>
                  <h3 className="heading">
                    {repo.name}
                    <span>
                      {getLink({
                        url: `https://github.com/${repo.userName}/${repo.repo}`,
                        title: 'Github',
                        icon: faGithub,
                        customClasses: 'repo-link',
                      })}
                    </span>
                  </h3>
                  <p className="version">Version {repo.version}</p>
                  <p className="desc">{repo.desc}</p>
                </header>
                {repo.hasOsMatch && (
                  <div className="platform-downloads">
                    {repo.downloads
                      ?.filter((download) =>
                        repo.hasOsMatch && repo.hasCpuMatch
                          ? download.osMatch && download.cpuMatch
                          : download.osMatch
                      )
                      .map((download) => (
                        <SiteButton
                          key={repo.downloads?.indexOf(download)}
                          label={'Download ' + download.name}
                          url={download.url}
                        />
                      ))}
                  </div>
                )}
                <div className="other-downloads">
                  <>
                    {repo.downloads?.map((download) => (
                      <a
                        href={download.url}
                        title={'Download ' + download.name}
                        key={repo.downloads?.indexOf(download)}
                      >
                        {download.name}
                      </a>
                    ))}
                    {!repo.downloads && <p>Error Fetching Downloads</p>}
                  </>
                </div>
              </div>
            ))}
          </div>
        </section>
      </div>
    </>
  );
}

export default Downloads;
