import Contact from "src/components/contact";
import image1 from "src/assets/about/about-image-1.jpeg";
import image2 from "src/assets/about/about-image-2.jpeg";
import { contactData } from "../data/project-data";
import { useEffect } from "react";
import { scrollTopFunction, onImageLoadUtility } from "src/utility/functions";

import "./about.scss";
import "./page.scss";

function About() {
  useEffect(() => {
    scrollTopFunction();
  }, []);

  return (
    <>
      <section className="outer-flex about section-content">
        <div className="inner-flex inner">
          <div className="content">
            <h1>About Us</h1>
            <div className="intro">
              <div className="content">
                <p>
                  Blink Labs began with the idea to run a Cardano stake pool in
                  the summer of 2021. We were operating as CloudStruct, a DevOps
                  as a Service consulting company and launched the CloudStruct
                  Cardano Staking pool which operated until the end of 2022.
                </p>
                <p>
                  Since then, we have focused on creating open source software
                  and custom solutions on the Cardano blockchain. Our seasoned
                  team at Blink Labs possesses a diverse range of expertise
                  acquired from decades of experience across a variety of
                  industries. Our experience ranges projects of all scale, from
                  one man solopreneurs to global-scale projects in
                  communications, storage, cloud computing, big data analytics,
                  and the highly regulated fields of global life science and
                  advertising. We harness this wealth of experience to craft
                  robust and scalable systems tailored specifically for the
                  Cardano blockchain.
                </p>
                <p>
                  Our primary mission is to deliver top-tier software and
                  services to enhance the Cardano ecosystem, streamlining
                  time-to-market and bolstering reliability through open source
                  solutions. We are dedicated to building high-quality, highly
                  available, and repeatable solutions that empower the Cardano
                  community and contribute to the blockchain's success.
                </p>
              </div>
              <img
                src={image1}
                alt="About"
                className="fade-in"
                onLoad={onImageLoadUtility}
              />
            </div>
          </div>
        </div>
      </section>
      <section className="outer-flex about section-content border">
        <div className="inner-flex inner">
          <img
            src={image2}
            alt="About"
            className="fade-in"
            onLoad={onImageLoadUtility}
          />
          <div className="content">
            <h2>Open source</h2>
            <p>
              At Blink Labs, our commitment to open source runs deep. We are
              passionate advocates of open source principles, and it's reflected
              in everything we do. From our Linux roots to our innovative tools
              and solutions for Cardano, openness and collaboration are at the
              core of our ethos. We believe in the power of transparency,
              community-driven innovation, and the limitless potential of open
              source technology. Join us on our journey as we continue to
              harness the collective brilliance of the open source community to
              build a brighter, more accessible future for the Cardano
              blockchain and beyond.
            </p>
            <p>
              Join us and get started on{" "}
              <a href="https://github.com/blinklabs-io">GitHub</a>!
            </p>
          </div>
        </div>
      </section>
      <section className="outer-flex team section-content border">
        <div className="inner-flex inner">
          <div className="content">
            <h2>Team</h2>
            <p>
              Our journey is guided by the fusion of decades of open source
              expertise and extensive corporate experience. This distinctive
              blend empowers us to offer unparalleled depth and breadth of
              knowledge. We possess an intimate understanding of the intricacies
              of software development, from initial information gathering to
              precise code deployment within your environment. Our versatility
              allows us to seamlessly scale from small projects to global
              initiatives, and we excel in delivering high-quality solutions
              that empower developers worldwide.
            </p>
          </div>
          <div className="contact-cards">
            {contactData.map((contact, i) => {
              return <Contact key={i} contactData={contact}></Contact>;
            })}
          </div>
        </div>
      </section>
    </>
  );
}

export default About;
